module controllers {
    export module customs {
        interface IRefundClaimList extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface IRefundClaimListParams extends ng.ui.IStateParamsService {
        }

        export class refundClaimListCtrl {

            static $inject = [
                "$scope",
                "$stateParams",
                "$rootScope",
                "customsService",
                "classificationValueService",
                "$timeout",
                "generalService",
                "$q",
                "$state",
                "entityService",
                "refundClaimService",
                'countryService',
                'refundClaimTypeService',
                "statusService",
                "$uibModal"
            ];

            refSearch: interfaces.customs.IRefundClaimSearch = <interfaces.customs.IRefundClaimSearch>{
                StatusId: 0
            };

            searchAccordian: boolean = false;

            ctrlName :string =  "Refund Claim List Ctrl"

            showGrid: boolean = false;
            IsLoading: boolean = false;

            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            selectedDeclarationCountry: interfaces.applicationcore.IDropdownModel;

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>(),
                RefundClaimTypeList: Array<interfaces.applicationcore.IDropdownModel>(),
                RefundClaimStatusList: Array<interfaces.applicationcore.IDropdownModel>()
            };

            filterNames: string[] = ["EDIStatusIndicator","EntityCode", "DeclarationCountryCode", "ReferenceNumber","ConsignmentNumber", "RefundClaimTypeCode", "SubmissionDate", "ClaimValue", "CurrencyCode", "SLADate", "Status","CustomsStatus","CustomsStatusDate","CreateDate","ResponsibleUser"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            apiRefList: uiGrid.IGridApi;
            selectedRef: uiGrid.IGridRow;

            sortName: string;
            sortDirection: string;

            constructor(
                private $scope: IRefundClaimList,
                private $stateParams: IRefundClaimListParams,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private customsService: interfaces.customs.ICustomsService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private refundClaimService: interfaces.customs.IRefundClaimService,
                private countryService: interfaces.applicationcore.ICountryService,
                private refundClaimTypeService: services.tariff.refundClaimTypeService,
                private statusService: interfaces.master.IStatusService,
                private $uibModal: ng.ui.bootstrap.IModalService) {

                this.loadControls();

                $timeout(() => {
                    this.showGrid = true;
                });
            }


            loadControls() {
                
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadEntities(),
                    this.getRefundClaimStatusList()
                ]

                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                });
                
            }

            getCurrentEntity(): ng.IPromise<boolean> {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            //Loading Entities
            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadRefundTypes() {
                return this.refundClaimTypeService.getDropdownList(this.selectedDeclarationCountry.Id).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCountryDeclarationList() {

                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }



            getRefundClaimStatusList() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({
                    type: Enum.EnumStatusType.RefundClaim
                }, (result) => {

                    this.dropdownsObject.RefundClaimStatusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }


            search_click() {
                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                if (this.apiRefList !== undefined) {
                    this.apiRefList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiRefList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiRefList.grid.getColumn(name).filters[0].term || this.apiRefList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiRefList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiRefList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiRefList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiRefList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection) {
                        this.apiRefList.grid.sortColumn(column, this.sortDirection);
                    }
                }
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                if (this.selectedDeclarationCountry) {
                    this.refSearch.DeclarationCountryId = this.selectedDeclarationCountry.Id;
                }

                angular.forEach(this.refSearch, (n, key) => {
                    if (n != null || n != undefined) {
                        if (n["Id"]) {
                            searchObject.filters.push({ Name: key, Value: n.Id });
                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiRefList) {
                    var grid = this.apiRefList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.IsLoading = true;
                this.refundClaimService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwRefList.data = data;
                    if (data[0]) {
                        this.gvwRefList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwRefList.totalItems = 0;
                    }

                    this.$timeout(() => {
                        if (this.selectedRef) {
                            var index = _.findIndex(this.gvwRefList.data, (o) => { return o.Id === this.selectedRef.entity.Id });
                            this.apiRefList.selection.selectRow(this.gvwRefList.data[index]);
                        }
                        this.getFilters();
                        this.setFilters();
                    });

                }, (data) => {
                    this.IsLoading = false;
                    this.gvwRefList.data = [];
                    this.gvwRefList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.refSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiRefList) {

                    var grid = this.apiRefList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.IsLoading = true;
                this.refundClaimService.getRefundClaimsExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwRefList.data = [];
                    this.gvwRefList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.showAll_click();
                this.apiRefList = gridApi;

                this.apiRefList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiRefList.core.on.sortChanged(this.$scope, () => { this.ListShortChange(gridApi) });
                this.apiRefList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiRefList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selectedRef = selected; });

                this.$timeout(() => {
                    if (this.selectedRef)
                        this.apiRefList.selection.selectRow(this.selectedRef.entity);
                });
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }   

            editRefundClaim(refId: number) {
                    this.$state.go("auth.RefundClaim.Update", { refId: refId });
            };

            create() {
                this.$state.go("auth.RefundClaim.Create", { refId: 0 });
            }

            printDocuments() {
                if (this.apiRefList && this.apiRefList.selection.getSelectedRows().length > 0) {

                    var selected = this.apiRefList.selection.getSelectedRows()[0];

                    this.$state.go("auth.RefundClaim.Update.Documents", { refId: selected.Id });

                } else {
                    this.generalService.displayMessage("Please select a Refund Claim", Enum.EnumMessageType.Warning);
                }
            }

            gvwRefList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)

                    this.apiRefList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiRefList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.SLAStatus === 1, 'SLA-danger': row.entity.SLAStatus === 2 }" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "Id",
                    displayName: "Id",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.refundClaimListCtrl.editRefundClaim(row.entity.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                        <button type="button" ng-click="grid.appScope.refundClaimListCtrl.openLinkedGoodsDecPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-edit"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 70
                },
                , {
                    name: "EDIStatusIndicator",
                    displayName: "EDI",
                    field: "EDIStatusIndicator",
                    enableCellEdit: false,
                    cellTemplate: `
                    <div style="text-align: center; padding-top: 6px;">
                        <div ng-if="row.entity.EDIStatusIndicator === 0">
                            <span class="fa fa-edit"></span>
                        </div>
                        <div ng-if="row.entity.EDIStatusIndicator === 4">
                            <span class="fa fa-times" style="color: #cb2330;"></span>
                        </div>
                        <div ng-if="row.entity.EDIStatusIndicator === 3">
                            <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                        </div>
                        <div ng-if="row.entity.EDIStatusIndicator === 1">
                            <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                        </div>
                    </div>                                                    
                    `,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 45,
                    enableCellEditOnFocus: false,
                },
                {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "DeclarationCountryCode",
                    displayName: "Country",
                    field: "DeclarationCountryCode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "ReferenceNumber",
                    displayName: "Reference",
                    field: "ReferenceNumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "RefundClaimTypeCode",
                    displayName: "Type",
                    field: "RefundClaimTypeCode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "CreateDate",
                    displayName: "Create Date",
                    field: "CreateDate",
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="CreateDate"></gts-date>
                                
                    </div>`,
                    width: 130,
                },
                {
                    name: "SubmissionDate",
                    displayName: "Submission Date",
                    field: "SubmissionDate",
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SubmissionDate"></gts-date>
                                
                    </div>`,
                    width: 130,
                },
                {
                    name: "ConsignmentNumber",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    width: 140,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                /*{
                    name: "MRNNumber",
                    displayName: "MRN",
                    field: "MRNNumber",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },*/
                {
                    name: "ClaimValue",
                    displayName: "Claim Value",
                    field: "ClaimValue",
                    width: 140,
                    enableFiltering:false
                },
                {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 80,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }
                    ,
                {
                    name: "SLADate",
                    displayName: "SLA",
                    field: "SLADate",
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLADate"></gts-date>
                                
                    </div>`,
                    width: 110,
                },
                {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 115,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "CustomsStatus",
                    displayName: "Customs Status",
                    field: "CustomsStatus",
                    width: 115,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm" >
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "CustomsStatusDate",
                    displayName: "Customs Status Date",
                    field: "CustomsStatusDate",
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLADate"></gts-date>
                                
                    </div>`,
                    width: 140,
                },
                {
                    name: "ResponsibleUser",
                    displayName: "Responsible User",
                    field: "ResponsibleUser",
                    width: 140,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }]
            };

            openLinkedGoodsDecPopup(refId: number) {
                //Open Popup.
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Refund Claim linked Goods Declarations</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <div id="gridTest" ui-grid="gvwSummary" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                                    ng-if="gvwSummary">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, refundClaimService: interfaces.customs.IRefundClaimService) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss(false);
                        };

                        $scope.gvwSummary = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                refundClaimService.GetCurrentGoodsDeclarations().query({refId:refId},(result: interfaces.customs.IRefundGoodsDeclarationDisplay) => {
                                    $scope.gvwSummary.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [{
                                name: "ConsignmentNumber",
                                displayName: "Consignment Number",
                                field: "ConsignmentNumber",
                                type: "string",
                                width: 135,
                            }, {
                                    name: "CPCCode",
                                displayName: "CPC",
                                    field: "CPCCode",
                                type: "string",
                                width: 75,
                            }, {
                                name: "MRNNumber",
                                displayName: "MRN Number",
                                    field: "MRNNumber",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                width: 145,
                            }, , {
                                    name: "AssessmentDate",
                                    displayName: "Assessment Date",
                                    field: "AssessmentDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                width: 147,
                            }, {
                                name: "Version",
                                displayName: "Version",
                                field: "Version",
                                width: 65,
                            }, {
                                    name: "FunctionType",
                                displayName: "Function",
                                    field: "FunctionType",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                type: "string",
                                width: 75,
                                }, {
                                    name: "Declarant",
                                    displayName: "Declarant",
                                    field: "Declarant",
                                    type: "string",
                                    width: 135,
                                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                }, {
                                    name: "ImporterExporter",
                                    displayName: "Importer Exporter",
                                    field: "ImporterExporter",
                                    type: "string",
                                    width: 135,
                                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                }]
                        };
                    },
                    size: "xlg",
                    resolve: {
                    }
                }).result;
            }
        }
        angular.module("app").controller("refundClaimListCtrl", controllers.customs.refundClaimListCtrl);
    }
}